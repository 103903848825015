import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Breadcrumb({ breadcrumb }) {
  const [path, setPath] = useState([]);

  useEffect(() => {
    setPath(breadcrumb.filter(i => i.breadcrumb && i.page && i.page.url));
  }, [breadcrumb]);

  if (!path || !path.length) return <></>;

  return (
    <div className="uk-visible@l">
      <section className="uk-section uk-section-small uk-section-muted">
        <div className="uk-container uk-container-expand uk-flex uk-flex-right">
          <ul className="uk-breadcrumb">
            {path.map(item => (
              <li key={item._id}>
                <Link to={item.page ? item.page.url : item.url}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}

function mapStateToProps({ breadcrumb }) {
  return { breadcrumb };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
